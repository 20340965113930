import { setAdobeEnvironment } from "./adobeAnalytics/config";
import { SetEnvConfig, environmentFileUrl } from "./config/environment/environment";

fetch(environmentFileUrl)
  .then((response) => response.json())
  .then((env) => {
    SetEnvConfig(env);
  })
  .then(() => {
    import("./bootstrap");
    setAdobeEnvironment();
  })
  .catch(() => {
    import("./404");
  });
